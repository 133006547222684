<template>
    <a-modal :title="handle === 'add' ? '新增' : handle === 'edit' ? '编辑' : '查看'" style="top: 8px;" :width="1000" v-model="visible" :footer="null" :maskClosable="false">
        <a-form-model ref="form" :model="rowData" :rules="formRule" layout="horizontal" :label-col="{span:3}" :wrapper-col="{span:21}">
          <a-form-model-item label="选择经销商" prop="(customerName">
            <SelectConsumerModal :disabled="handle == 'check'" :value.sync="rowData.customerName" @select="toSelectConsumer"></SelectConsumerModal>
          </a-form-model-item>
            <a-form-model-item label="选择要货单" prop="">
              <SelectDemandRequisitionModal :disabled="handle == 'check'" :value.sync="rowData.orderId" :customerid="rowData.customerId"  @select="toSelectOrder"></SelectDemandRequisitionModal>
            </a-form-model-item>
            <a-form-model-item label="订单总金额" prop="(orderAmount">
                <a-input :disabled="handle == 'check'" v-model="rowData.orderAmount" disabled placeholder="订单总金额"></a-input>
            </a-form-model-item>
            <a-form-model-item label="收款金额" prop="(amount">
                <a-input :disabled="handle == 'check'" v-model="rowData.amount" placeholder="本次付款金额"></a-input>
            </a-form-model-item>
          <a-form-model-item label="付款凭证" class="buju" prop="specStr">
            <QiniuUpload type="image" :value.sync="rowData.paymentVoucher"></QiniuUpload>
            <div class="upload-hint-text">支持扩展名：.jpg .png</div>
          </a-form-model-item>
          <a-form-model-item label="是否可发货" prop="flagEnable">
            <a-switch :disabled="handle == 'check'" v-model="rowData.flagSend" checked-children="是" un-checked-children="否" default-checked />
          </a-form-model-item>
          <a-form-model-item label="备注说明" prop="(remark">
            <a-textarea :disabled="handle == 'check'" style="width: 45%" v-model="rowData.description" placeholder="请输入描述说明"></a-textarea>
          </a-form-model-item>
        </a-form-model>
      <div class="footer-bts" v-if="handle != 'check'">
        <a-button key="back" @click="handleCancel">取消</a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="toSubmit">提交</a-button>
      </div>
    </a-modal>

</template>

<script>
import SelectConsumerModal from "@/views/common/SelectConsumerModal.vue"
import SelectDemandRequisitionModal from "@/views/common/SelectDemandRequisitionModal.vue"
import { editFinanceReceipt, selectByIdFinanceReceipt, addFinanceReceipt } from '../api/FinanceReceiptApi'
export default {
    data() {
        return {
            visible: false,
            handle: 'add',
            loading: false,
            rowData: {},
            // 表单验证
            formRule: {
                orderId: [
                    { required: true, message: '请输入订单编号', trigger: 'blur' }
                ],
                orderAmount: [
                    { required: true, message: '请输入订单总金额', trigger: 'blur' }
                ],
                amount: [
                    { required: true, message: '请输入本次付款金额', trigger: 'blur' }
                ],
                customerName: [
                    { required: true, message: '请输入经销商名称', trigger: 'blur' }
                ],
                paymentVoucher: [
                    { required: true, message: '请输入付款凭证', trigger: 'blur' }
                ],
            }
        }
    },
    components: {
      SelectConsumerModal,
      SelectDemandRequisitionModal
    },
    methods: {

      // 关闭弹框
      handleCancel() {
        this.rowData = {}
        // this.typeSpecTableData = []
        // this.typeSpecList = []
        // this.categoryIds = []
        // this.brandId = []
        this.visible = false
      },
        /**
         * 获取行数据
         */
        setRowData(row, handle){
            this.handle = handle
            this.visible = true
            this.rowData = row
            if(handle == 'add') {
              this.$set(this.rowData, 'flagSend', true)
            }
        },
        /**
         * 表单提交
         */
        toSubmit() {
            this.$refs.form.validate(async valid => {
                if (!valid) {
                    return
                }
                const res = this.handle === 'add' ? await addFinanceReceipt(this.rowData) : await editFinanceReceipt(this.rowData)
                if (res.code === 200) {
                    this.$notification.success({ message: res.message })
                    this.$emit('reload')
                    this.visible = false
                    this.rowData = {}
                } else {
                    this.$notification.error({ message: res.message })
                }
            })
        },
        toSelectConsumer(row) {
          this.$nextTick(() => {
            this.$set(this.rowData, 'customerId', row.id)
            this.$set(this.rowData, 'customerName', row.fname)
          })
        },
        toSelectOrder(row) {
          this.$set(this.rowData,'orderId',row.orderCode)
          this.$set(this.rowData,'orderAmount',row.payAmount)
        },
    },
    created() {

    },

}
</script>

<style lang="less" scoped>

.footer-bts {
  text-align: right;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #E9E9E9;
}
</style>
