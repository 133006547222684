<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-01-21 16:31:02
 * @LastEditors: hutian
 * @LastEditTime: 2021-02-01 16:08:36
-->
<template>
  <div class="">
    <a-input-group compact>
      <a-input style="width: 80%;" v-model="name" disabled placeholder="请查询经销商" allowClear></a-input>
      <a-button style="width: 20%;margin: 0;" @click="toSearch" icon="search"></a-button>
    </a-input-group>

    <a-modal :maskClosable="false" title="查找经销商" style="top: 8px;" :width="1000" v-model="visible" :footer="null" @cancel="closeModal">
      <a-input-search v-model="fName" placeholder="经销商名称" @search="toSearch" style="width: 30%;margin-bottom: 20px;">
        <a-button slot="enterButton">搜索</a-button>
      </a-input-search>
      <a-input-search v-model="dealerCode" placeholder="经销商编码" @search="toSearch" style="width: 30%;margin-bottom: 20px;margin-left: 20px">
        <a-button slot="enterButton">搜索</a-button>
      </a-input-search>
      <div class="table">
        <a-table size="small" :data-source="tableData" bordered rowKey="id" :pagination="page" @change="p=>{page=p;toSearch(2)}" :customRow="changeTableRow">
          <a-table-column title="经销商编码" data-index="dealerCode" align="left">
          </a-table-column>
          <a-table-column title="经销商名称" data-index="fname" align="left">
          </a-table-column>
          <a-table-column title="经销商简称" data-index="fshortName" :width="150" align="left">
          </a-table-column>
          <a-table-column title="联系人" data-index="contactPerson" :width="100" align="left">
          </a-table-column>
          <a-table-column title="联系电话" data-index="mobile" :width="150" align="left">
          </a-table-column>
          <a-table-column title="地址" data-index="endTime" align="left">
            <template slot-scope="text, record">{{record.province}}{{record.city}}{{record.district}}{{record.address}}</template>
          </a-table-column>
        </a-table>

      </div>
    </a-modal>
  </div>
</template>

<script>
  const qs = require('qs')
  export default {
    data() {
      return {
        visible: false,
        name: '',
        fName: '',
        tableData: [],
        dealerCode: '',
        page: {
          current: 1,
          pageSize: 10,
          total: 0,
          size: 'small'
        },

      }
    },
    props: ['value'],
    watch: {
      value(newVal) {
        this.name = newVal
      }
    },
    methods: {
      closeModal() {
        this.fName = '',
        this.dealerCode = ''
      },
      toSearch(type) {
        this.visible = true
        const postData = Object.assign({}, {
          fName: this.fName,
          dealerCode: this.dealerCode,
          pageNumber: type == 2 ? this.page.current : this.page.current = 1,
          pageSize: this.page.pageSize
        })
        this.axios.get(`/api/customer/customer/customerInfo/list?${qs.stringify(postData)}`).then(res => {
          this.tableData = res.body.records
          this.page.total = res.body.total
        }).catch(err => {})
      },
      changeTable(pagination, filters, sorter) {
        this.page = pagination
        this.toSearch(2)
      },
      changeTableRow(record) {
        return {
          on: {
            dblclick: e => {
              this.visible = false
              this.$emit('update:value', record.customerName)
              this.$emit('select', record)
            }
          }
        }
      },
    },
    created() {
      if (this.value) {
        this.name = this.value
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
