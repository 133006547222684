<template>
  <a-card :bordered="false">
    <div class="realtor">
      <!-- 查询表单 -->
      <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
        <a-row :gutter="8">
          <a-col :md="8" :xl="8" :xxl="8" :sm="12">
            <a-form-model-item label="关联单号">
              <a-input v-model="searchData.dealNo" placeholder="请输入关联单号" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="8" :xxl="8" :sm="12">
            <a-form-model-item label="交易类型">
              <DictSelect
                field="dealType"
                :value.sync="searchData.dealType"
                style="width: 100%"
                placeholder="请选择交易状态"
              ></DictSelect>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="8" :xxl="8" :sm="12">
            <a-form-model-item label="金额类型">
              <DictSelect
                field="amountType"
                :value.sync="searchData.amountType"
                style="width: 100%"
                placeholder="请选择金额状态"
              ></DictSelect>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="8" :xxl="8" :sm="12">
            <a-form-model-item label="交易时间">
              <DateRange :startTime.sync="searchData.startDate" :endTime.sync="searchData.endDate"></DateRange>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="6" :xl="5" :md="8" sm="2">
            <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-button type="primary" @click="getList()" icon="search">查询</a-button>
              <a-button type="default" @click="reset()" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div class="Row_Balance">
        <a-row type="flex" justify="space-around">
          <a-col :span="6">
            <div class="Col_Balance">
              <div class="balance_left">
                <div class="icon_left">
                  <a-icon type="account-book" style="hight: 100%; width: 100%; font-size: 50px" />
                </div>
              </div>
              <div class="balance_right">
                <div class="P-Right">
                  <p>{{ dealerAmount.income | formatMoney }}</p>
                  <p>收入</p>
                </div>
              </div>
            </div>
          </a-col>
          <a-col :span="6">
            <div class="Col_Balance">
              <div class="balance_left">
                <div class="icon_left1">
                  <a-icon type="pay-circle" style="hight: 100%; width: 100%; font-size: 50px" />
                </div>
              </div>
              <div class="balance_right">
                <div class="P-Right">
                  <p>{{ dealerAmount.spending | formatMoney }}</p>
                  <p>支出</p>
                </div>
              </div>
            </div>
          </a-col>
          <a-col :span="6">
            <div class="Col_Balance">
              <div class="balance_left">
                <div class="icon_left1">
                  <a-icon type="money-collect" style="hight: 100%; width: 100%; font-size: 50px" />
                </div>
              </div>
              <div class="balance_right">
                <div class="P-Right">
                  <p>{{ dealerAmount.amount | formatMoney }}</p>
                  <p>剩余</p>
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
      <!--      <div>-->
      <!--        <a-button v-if="isCheck" type="primary" @click="toHandler('check')" icon="copy">查看</a-button>-->
      <!--        <a-button v-if="isRecharge" type="primary" @click="toHandler('recharge')" icon="pay-circle">充值</a-button>-->
      <!--      </div>-->
      <!-- 数据表格 -->

      <a-table
        :columns="columns"
        :data-source="tableData"
        :pagination="page"
        @change="changeTable"
        :rowKey="(record) => record.id"
        bordered
        :maskClosable="false"
      >
        <span slot="dealType" slot-scope="text">
          <a-tag color="" v-if="text == 1">支付</a-tag>
          <a-tag color="" v-if="text == 2">提现</a-tag>
          <a-tag color="" v-if="text == 3">充值</a-tag>
          <a-tag color="" v-if="text == 4">退款</a-tag>
          <a-tag color="" v-if="text == 5">订单结算</a-tag>
          <a-tag color="" v-if="text == 6">订单取消</a-tag>
          <a-tag color="" v-if="text == 7">提现取消/驳回</a-tag>
          <a-tag color="" v-if="text == 8">订单退款</a-tag>
          <a-tag color="" v-if="text == 9">平台操作</a-tag>
          <a-tag color="" v-if="text == 10">还款</a-tag>
        </span>
        <span slot="amountType" slot-scope="text">
          <a-tag color="#87d068" v-if="text == 1">收入</a-tag>
          <a-tag color="#f50" v-if="text == 2">支出</a-tag>
        </span>

        <span slot="amount" slot-scope="text"> {{ text | formatMoney }} </span>
        <span slot="dealAmount" slot-scope="text, row"
          >{{ row.amountType == 1 ? '' : '-' }} {{ text | formatMoney }}
        </span>
        <span slot="balance" slot-scope="text"> {{ text | formatMoney }} </span>
        <span slot="dealNo" slot-scope="text,row">
          <a @click="checkOrderInfo(row.dealNo,row.dealType)">{{ text }}</a>
        </span>
      </a-table>
    </div>
    <OrderInfoCheckModal ref="OrderInfoCheckModal"></OrderInfoCheckModal>
    <OrderShipInfoEditModal ref="OrderShipInfoEditModal"></OrderShipInfoEditModal>
    <FinanceReceiptInfoModal ref="FinanceReceiptInfoModal"></FinanceReceiptInfoModal>
  </a-card>
</template>

<script>
import OrderShipInfoEditModal from "@/views/dealer_balance/OrderShipInfoEditModal";
import OrderInfoCheckModal from "@/views/order_info/components/OrderInfoCheckModal";
import FinanceReceiptInfoModal from '@/views/finance_receipt/components/FinanceReceiptInfoModal';
import DealerBalanceDetailed from '@/views/dealer_balance/DealerBalanceDetailed'
import FinanceReceiptEditModal from '@/views/finance_receipt/components/FinanceReceiptEditModal.vue'
import { checkPermission } from '@/utils/permissions'
import qs from 'qs'

export default {
  name: 'DealerBalance',

  components: {
    DealerBalanceDetailed,
    FinanceReceiptEditModal,
    OrderInfoCheckModal,
    OrderShipInfoEditModal,
    FinanceReceiptInfoModal,
  },

  data() {
    return {
      columns: [
        {
          title: '交易时间',
          dataIndex: 'dealDate',
          align: 'center',
          width: 220,
          key: 'dealDate',
        },
        {
          title: '交易前金额（元）',
          dataIndex: 'amount',
          align: 'center',
          key: 'amount',
          width: 150,
          scopedSlots: { customRender: 'amount' },
        },
        {
          title: '交易金额（元）',
          dataIndex: 'dealAmount',
          align: 'center',
          key: 'dealAmount',
          width: 150,
          scopedSlots: { customRender: 'dealAmount' },
        },
        {
          title: '交易后金额（元）',
          dataIndex: 'balance',
          align: 'center',
          key: 'balance',
          width: 150,
          scopedSlots: { customRender: 'balance' },
        },
        {
          title: '交易类型',
          dataIndex: 'dealType',
          align: 'center',
          width: 100,
          key: 'dealType',
          scopedSlots: { customRender: 'dealType' },
        },
        {
          title: '金额类型',
          dataIndex: 'amountType',
          align: 'center',
          width: 100,
          key: 'amountType',
          scopedSlots: { customRender: 'amountType' },
        },
        {
          title: '关联单号',
          dataIndex: 'dealNo',
          align: 'center',
          width: 300,
          key: 'dealNo',
          scopedSlots: { customRender: 'dealNo' },
        },
      ],
      visible: false,
      tableData: [],
      dealerAmount: {},
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      selectedRows: [],
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
    getToken() {
      return this.$sotre.state.SET_TOKEN()
    },
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.page.current = 1
      this.page.pageSize = 10
      this.getData()
    },

    getData() {
      let params = {
        pageSize: this.page.pageSize,
        pageNumber: this.page.current,
      }
      const postData = Object.assign(params, this.searchData)
      // this.axios.get('/api/dealer/dealer/dealerPayRecord/dealerById', {params}).then(res => {
      this.axios
        .get(
          `/api/dealer/dealer/dealerPayRecord/selectPayRecordForDealer?${qs.stringify(postData, {
            arrayFormat: 'repeat',
          })}`
        )
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
      this.axios.get('/api/dealer/dealer/dealerPayRecord/selectDealerAmount').then(res => {
        this.dealerAmount = res.body
      })
    },
    changeTable(pagination) {
      this.page = pagination
      this.page.pageNumber = pagination.current
      this.getData()
    },
    toHandler(name) {
      if (this.selectedRows.length <= 0) {
        return this.$message.warning('请至少选择一条数据')
      }
      if (name == 'check') {
        this.$refs.DealerBalanceDetailed.setRowData(this.selectedRows[0])
      } else if (name == 'recharge') {
        this.$refs.FinanceReceiptEditModal.setRowData(this.selectedRows[0], 'recharge')
      }
    },

    onChange(val) {
      this.searchData.areaId = val[0]
      this.searchData.firmId = val[1]
    },

    //打开上游单据
    checkOrderInfo(orderCode,dealType) {
      console.log(orderCode)
      const data = {
        orderCode: orderCode,
      }
      if(dealType == 3){
        this.$refs.FinanceReceiptInfoModal.setRowDataByCode(orderCode, 'check')
      }else{
        if(orderCode.search('DO') !== -1){
          this.$refs.OrderShipInfoEditModal.setRowDataByCode(data, 'check')
        }else{
          this.$refs.OrderInfoCheckModal.isShowByOrderCode(data, 'check')
        }
      }
    },

    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      this.regionalOffice = []
      this.page = Object.assign({}, this.page)
      this.page.current = 1
      this.page.pageSize = 10
      this.getData()
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },
  },
}
</script>

<style scoped lang="less">
.Row_Balance {
  margin: 0 0 20px 0;
}
.Col_Balance {
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ececec;
  .balance_left {
    flex: 2;
    padding-left: 20px;
    .icon_left {
      height: 60px;
      width: 60px;
      border-radius: 25%;
      color: rgb(92, 92, 236);
    }
    .icon_left1 {
      height: 60px;
      width: 60px;
      border-radius: 25%;
      color:rgb(129, 129, 8);
    }
    .icon_left2 {
      height: 60px;
      width: 60px;
      border-radius: 25%;
      color: rgb(104, 104, 133);
    }
  }
  .balance_right {
    flex: 6;
    .P-Right {
      height: 60px;
      width: 60px;
      p {
        padding-top: 5px;
        margin-bottom: 0;
      }
    }
  }
}
</style>