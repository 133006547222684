<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-06-01 15:48:45
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-07 19:10:18
-->
<template>
  <div class="">
    <a-modal
      :maskClosable="false"
      :title="'物流信息'"
      style="top: 8px"
      :width="800"
      v-model="visible"
      :confirmLoading="isLoading"
      @cancel="toCancel"
      :footer="null"
    >
      <div style="margin-bottom: 30px; display: flex;align-items: center;">
        <div class="ship_logo"><img :src="deliveryinfo.ShipperLogo" alt="" /></div>
        <div style="margin-left: 30px">
          <div style="margin-bottom: 5px">
            <span>承运公司：</span><span>{{ !deliveryinfo.ShipperName ? '暂无' : deliveryinfo.ShipperName }}</span>
          </div>
          <div>
            <span>快递单号： </span><span>{{ !deliveryinfo.LogisticCode ? '暂无' : deliveryinfo.LogisticCode }}</span>
          </div>
        </div>
      </div>
      <div style="height: 1px; background: #e9e9e9; margin: 20px 0 30px 0"></div>


      <div style="width: 100%; margin-left: 20%">
        <a-timeline>
          <a-timeline-item v-for="(item, index) in tableData" :key="index">
            <div v-if="item.action">物流状态: {{ item.action }}</div>
            <div v-if="item.logisticsPicture">
              <img :src="item.logisticsPicture" style="width: 100px; height: 100px" /><br />
            </div>
            {{ item.acceptStation }} <br />
            <div v-if="item.location">当前所在城市: {{ item.location }}<br /></div>
            {{ item.acceptTime }}
          </a-timeline-item>
        </a-timeline>
      </div>
      <div style="text-align: right">
        <a-button type="default" @click="toCancel">关闭</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
const qs = require('qs')

export default {
  name: '',
  data() {
    return {
      isLoading: false,
      visible: false,
      tableData: [],
      deliveryinfo: {},
    }
  },

  computed: {},
  created() {},
  methods: {
    getData(row, name) {
      this.visible = true
      let obj = {
        customerName: row.phone.substr(row.phone.length-4) ,
        logisticCode: row.deliveryNum,
        shipperCode: row.deliveryCode
      }
      this.axios.post(`/api/order/logistics/logisticsInfo/queryDeiveryExpressList`, obj).then((res) => {
        this.tableData = res.body.Traces
        this.deliveryinfo = res.body
      })
    },

    toCancel() {
      this.$refs.form && this.$refs.form.resetFields()
      this.visible = false
    },
  },
}
</script>

<style lang='scss' scoped>
.ship_logo {
  width: 130px;
  height: 100px;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>