<template>
  <a-modal :title="'查看打款单【'+rowData.collectionCode+'】'" style="top: 8px;" :width="1000" v-model="visible" :footer="null" :maskClosable="false">
    <a-tabs v-model="checkedTabs" @change="callback">
      <a-tab-pane key="base_info" tab="基本信息">
        <a-form-model ref="form" :model="rowData" layout="horizontal" :label-col="{span:6}" :wrapper-col="{span:18}">
          <a-row>
            <a-col :md="12">
              <a-form-model-item label="打款单号">
                {{ rowData.collectionCode }}
              </a-form-model-item>
            </a-col>
            <a-col :md="12">
              <a-form-model-item label="经销商">
                {{ rowData.dealerName }}
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :md="12">
              <a-form-model-item label="大区/事务所">
                {{ rowData.areaName }}/{{rowData.firmName}}
              </a-form-model-item>
            </a-col>
            <a-col :md="12">
              <a-form-model-item label="业绩归属">
                {{ rowData.belongDealerName }}
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :md="12">
              <a-form-model-item label="负责人">
                {{ rowData.lingganResponsibilityDep }} {{rowData.lingganResponsibilityName}}
              </a-form-model-item>
            </a-col>
            <a-col :md="12">
              <a-form-model-item label="收款金额">
                {{ rowData.amount ? rowData.amount.toFixed(2) : 0.00 }}
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :md="12">
              <a-form-model-item label="打款类型">
                {{ rowData.paymentType == 1 ? '个人' : '公司' }}
              </a-form-model-item>
            </a-col>
            <a-col :md="12">
              <a-form-model-item label="金额类型">
                {{rowData.amountType == 1 ? '承兑' : rowData.amountType == 2 ? '电汇' : '--'}}
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <!--            <a-col :md="12" v-if="rowData.amountType == 2">
                          <a-form-model-item label="金额类型来源">
                            {{ rowData.amountTypeSource | amountTypeSource }}
                          </a-form-model-item>
                        </a-col>-->
            <a-col :md="12" v-if="rowData.amountType == 1">
              <a-form-model-item label="兑换时长">
                {{ rowData.acceptanceName }}
              </a-form-model-item>
            </a-col>

            <a-col :md="12">
              <a-form-model-item label="用途">
                {{ rowData.topUpPurpose }}
              </a-form-model-item>
            </a-col>

          </a-row>
          <a-row>
            <a-col :md="12">
              <a-form-model-item label="所属渠道">
                {{ rowData.channel == 1 ? '三菱' :  rowData.channel == 2 ? '非三菱' : '--'}}
              </a-form-model-item>
            </a-col>
            <a-col :md="12">
              <a-form-model-item label="所属分类">
                {{ rowData.categoryName }}
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :md="12">
              <a-form-model-item label="创建人">
                {{ rowData.createUser}}
              </a-form-model-item>
            </a-col>
            <a-col :md="12">
              <a-form-model-item label="创建时间">
                {{ rowData.createTime }}
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :md="12">
              <a-form-model-item label="备注说明">
                {{rowData.remark}}
              </a-form-model-item>
            </a-col>
            <a-col :md="12">
              <a-form-model-item label="付款凭证" class="buju" >
                <div v-if="rowData.paymentVoucher == null">暂无</div>
                <QiniuUpload v-else :disabled="true" type="image" :value.sync="rowData.paymentVoucher"></QiniuUpload>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-tab-pane>

      <a-tab-pane key="operating_info" tab="操作日志">
        <LogPage :formId="rowData.id" ref="LogPage"></LogPage>
      </a-tab-pane>
    </a-tabs>
    <div class="footer-bts">
      <a-button key="back" @click="handleCancel">关闭</a-button>
    </div>
  </a-modal>

</template>

<script>
import {listLog,selectByIdFinanceReceipt, selectByOrderCodeFinanceReceipt} from '../api/FinanceReceiptApi'
import LogPage from '@/views/finance_receipt/components/LogPage.vue'

export default {
  data() {
    return {
      visible: false,
      loading: false,
      rowData: {},
      checkedTabs: 'base_info',
    }
  },

  components: {
    LogPage
  },

  /*filters: {
    "amountTypeSource"(service) {
      const serviceMap = {
        1: '天猫支付宝',
        2: '菱感支付宝',
        3: '淘宝支付宝',
        4: '物优家微信',
        5: '内购微信',
        6: '延保清洁微信',
        7: '农行汇款'
      }
      return serviceMap[service]
    }
  },*/

  methods: {

    // 禁用弹框
    handleCancel() {
      this.rowData = {}
      this.visible = false
      this.$emit('reload')
    },
    /**
     * 获取行数据
     */
    setRowData(row){
      this.visible = true
      this.rowData = {}
      this.checkedTabs = 'base_info'
      selectByIdFinanceReceipt(row.id).then(res => {
        this.rowData = res.body
      })
    },
    /**
     * 获取行数据
     */
    setRowDataByCode(collectionCode){
      this.visible = true
      this.rowData = {}
      this.checkedTabs = 'base_info'
      selectByOrderCodeFinanceReceipt(collectionCode).then(res => {
        this.rowData = res.body
      })
    },

    callback(key) {
      if (key == 'operating_info') {
        this.queryParam = {
          id: this.rowData.id
        }
        let refpage = {
          current: 1,
          pageSize: 10,
          total: 0
        }
        const params = Object.assign({}, this.page, this.queryParam)
        listLog(params).then(res => {
          this.$refs.LogPage.getList(res,this.rowData.id,refpage)
        })


      }
    },

  },
  created() {

  },

}
</script>

<style lang="less" scoped>
.ant-form-item {
  margin-bottom: 10px;
}
.info-item {
  background-color: #f4f4f4;
  padding-left: 10px;
  border-radius: 3px;
  min-height: 40px;
}
.footer-bts {
  text-align: right;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #E9E9E9;
}
</style>
